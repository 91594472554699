module.exports = new Promise(resolve => {
                const remoteDomain = window._env_.APP_PHOENIX_URL.replace("/enterpriseworkloads", "");
                let remoteUrl = remoteDomain + "/mfs/alerts-ui/remoteEntry.js"
                if (false){
                  // remoteUrl = "http://localhost:2000/remoteEntry.js"
                  // for now using dneji, but following remoteUrl will be change to production once released
                  remoteUrl = "https://console.druva.com/mfs/alerts-ui/remoteEntry.js"
                }
                const script = document.createElement('script')
                script.src = remoteUrl
                script.onload = () => {
                  // the injected script has loaded and is available on window
                  // we can now resolve this Promise
                  const proxy = {
                    get: (request) => window.dcp_alerts.get(request),
                    init: (arg) => {
                      try {
                        return window.dcp_alerts.init(arg)
                      } catch(e) {
                        console.log('remote container already initialized')
                      }
                    }
                  }
                  resolve(proxy)
                }
                // inject this script with the src set to the versioned remoteEntry.js
                document.head.appendChild(script);
              })
              ;